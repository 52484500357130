<template>
 <div class="ping">
    <el-table
        :data="tableData"
        border
        show-summary
        style="width: 100%">
        <el-table-column  label="抽签房源信息" align="center">
        <el-table-column prop="mian" label="户型面积" align="center">
        </el-table-column>
        <el-table-column prop="hux" label="户型" align="center">
        </el-table-column>
        <el-table-column prop="tao" label="房源总套数" align="center">
        </el-table-column>
        <el-table-column prop="fang" label="当前剩余房源套数" align="center">
        </el-table-column>
        </el-table-column>
    </el-table>
 </div>
</template>

<script>
export default {
  data () {
    return {
         tableData: [{
          shun:'vip001',
          bian:'CE-001',
          date: '20201223/08：30：38',
          name: '王小虎',
          tao:2000,
          zu:"团1",
          lou:'1栋3单元',
          fang:'75',
          mian:'120',
          hux:'一室一厅'
        },{
          shun:'vip001',
          bian:'CE-001',
          date: '20201223/08：30：38',
          name: '王小虎',
          tao:2000,
          zu:"团1",
          lou:'1栋3单元',
          fang:'75',
          mian:'120',
          hux:'一室一厅'
        },{
          shun:'vip001',
          bian:'CE-001',
          date: '20201223/08：30：38',
          name: '王小虎',
          tao:2000,
          zu:"团1",
          lou:'1栋3单元',
          fang:'75',
          mian:'120',
          hux:'一室一厅'
        },{
          shun:'vip001',
          bian:'CE-001',
          date: '20201223/08：30：38',
          name: '王小虎',
          tao:2000,
          zu:"团1",
          lou:'1栋3单元',
          fang:'75',
          mian:'120',
          hux:'一室一厅'
        },{
          shun:'vip001',
          bian:'CE-001',
          date: '20201223/08：30：38',
          name: '王小虎',
          tao:2000,
          zu:"团1",
          lou:'1栋3单元',
          fang:'75',
          mian:'120',
          hux:'一室一厅'
        },,]
    }
  }
}

</script>
<style lang="less" scoped>
.ping{
    width: 100vw;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
}
</style>
